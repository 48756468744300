:root{
    --PrimaryColor: hsl(26, 93%, 50%);
    --HoverColor: hsl(26, 90%, 42%);
    --whiteColor: hsl(0, 0%, 100%);
    --blackColor: hsl(0, 0%, 18%);
    --textColor: hsl(240, 1%, 48%);
    --whiteColorDream: hsl(0, 0%, 96%);
    --greyBg: hsl(0, 0%, 96%);
    --greyText: rgb(190, 190, 190);
    --inputColor: hsl(330, 12%, 97%);
    --box-shadow: 0 2px 8px 2px rgba(178, 178, 178, 0.334); 
}

.offer{
    .secContainer{
        .mainContent{
            gap: 1rem;
            .singleOffer{
                border-radius: 1rem;
                overflow: hidden;
                padding: .5rem;
                box-shadow: 0 2px 8px 2px rgba(178, 178, 178, 0.334);

                &:hover{
                    transform: translateY(-7px);
                }
            }
            .icon{
                font-size: 20px;
                color: var(--textColor);
            }
            small{
                font-weight: 500;
                color: var(--textColor);
            }

            .destImage{
                position: relative;
                height: 200px;
                width: 100%;
                border-radius: 1rem;
                overflow: hidden;
                
                img{
                    height: 100%;
                }

                .discount{
                    position: absolute;
                    top: 10px;
                    right: 10px;
                    background: var(--PrimaryColor);
                    padding: 5px 10px;
                    color: var(--whiteColor);
                    border-radius: 3rem;
                    font-size: 10px;
                    font-weight: 500;
                }
            }
            .offerBody{
                padding: 1rem;

                .price{
                    justify-content: space-between;

                    h4{
                        color: var(--blackColor);
                        font-weight: 700;
                        font-size: 1.1rem;
                    }

                    .status{
                        padding: 5px 10px;
                        color: var(--HoverColor);
                        background: var(--greyBg);
                        font-size: 10px;
                        font-weight: 600;
                        border-radius: 3rem;
                    }
                }
                .amenities{
                    width: 100%;
                    flex-wrap: wrap;
                    justify-content: flex-start;
                    padding: 1rem 0;
                    gap: .5rem;

                    .singleAmenity{
                        padding: 5px 10px;
                        border-radius: 5px;
                        background: var(--greyBg);
                        gap: .5rem;
                    }
                 
                }

                .btn{
                    margin-top: 1rem;
                    width: 100%;
                    text-align: center;
                    justify-content: center;
                    padding: .6rem;
                    color: var(--whiteColor);
                    font-weight: 600;
                    gap: .5rem;

                    .icon{
                        color: var(--whiteColor);
                    }
                    
                }
                .btn:hover{
                    transform: translateY(-2px);
                    transition: .3s ease;

                    .icon{
                        transform: translateX(5px);
                    }
                }
                
            }
          
        }
    }
}


@media screen and (min-width: 720px){
    .offer{
        margin-top: 1rem;
    }
}