:root{
    --PrimaryColor: hsl(26, 93%, 50%);
    --HoverColor: hsl(26, 90%, 42%);
    --whiteColor: hsl(0, 0%, 100%);
    --blackColor: hsl(0, 0%, 18%);
    --textColor: hsl(240, 1%, 48%);
    --whiteColorDream: hsl(0, 0%, 96%);
    --greyBg: hsl(0, 0%, 96%);
    --greyText: rgb(190, 190, 190);
    --inputColor: hsl(330, 12%, 97%);
    /* box-shadow: 0 2px 8px 2px rgba(178, 178, 178, 0.334); */
   
}

.about{
    .secContainer{
        .title{
            color: var(--blackColor);
            line-height: 1.5rem;
            padding-bottom: .5rem;
            text-align: center;
            font-size: .1.5rem;
        }

        .mainContent{
            gap: 1rem;
            .singleItem{
                text-align: center;

                img{
                    background: rgba(247, 144, 54, 0.265);
                    width: 100px;
                    border-radius: 50%;
                    padding: 1rem;

                }
                h3{
                    font-weight: 700;
                    color: var(--blackColor);
                    padding: .5rem;
                }

                p{
                    font-size: 13px;
                    font-weight: 500;
                    padding-bottom: 0.5rem;
                    color: var(--textColor);
                }
            }
        }

        .videoCard{
            margin-top: 2rem;
            background: linear-gradient(to top, rgba(0,0,0,0.73), rgba(0,0,0,0.609)), url(../../assets/9.jpg);
            background-position: center;
            background-size: cover;
            background-repeat: no-repeat;


            .cardContent{
                gap: 2rem;
                padding: 2rem 1rem;

                .cardText{
                    h2{
                        color: var(--whiteColor);
                        font-weight: 600;
                        padding-bottom: 1rem;

                    }

                    p{
                        color: var(--whiteColor);
                        opacity: .7;
                        font-size: 12px;
                        font-weight: 400;
                        letter-spacing: 1px;
                    }
                }

            }
            .cardVideo{
                border: 2px solid var(--whiteColor);
                height: 200px;
                width: 100%;
                border-radius: 10px;
                overflow: hidden;

                video{
                    height: 100%;
                    width: 100%;
                    object-fit: cover;
                }
            }
        }
    }
}

@media screen and (min-width: 720px) {
    .about{
        .secContainer{
            .videoCard{
                .cardContent{
                    gap: 4rem;
                    padding: 4rem 3rem;

                    .cardText{
                        h2{
                            font-size: 2rem;
                        }

                        p{
                            opacity: .7;
                            font-size: 15px;
                        }
                    }
                }
                .cardContent{
                    grid-template-columns: repeat(2, 1fr) !important;
                }
            }
        }
    }
}


@media screen  and (min-width: 1024px){
    .about{
        .secContainer{
            .mainContent{
                padding: 2.5rem 0;
            }
        }
    }
}