:root{
    --PrimaryColor: hsl(26, 93%, 50%);
    --HoverColor: hsl(26, 90%, 42%);
    --whiteColor: hsl(0, 0%, 100%);
    --blackColor: hsl(0, 0%, 18%);
    --textColor: hsl(240, 1%, 48%);
    --whiteColorDream: hsl(0, 0%, 96%);
    --greyBg: hsl(0, 0%, 96%);
    --greyText: rgb(190, 190, 190);
    --inputColor: hsl(330, 12%, 97%);
    /* box-shadow: 0 2px 8px 2px rgba(178, 178, 178, 0.334); */
}

.blog{
    .mainContainer{
        gap: 1rem;

        .singlePost{
            gap: 2rem;

            .imgDiv{
                height: 200px;
                width: 100%;
                border-radius: 1rem;
                box-shadow: 0 2px 8px 2px rgba(178, 178, 178, 0.405);
                overflow: hidden;
                border: 5px solid var(--greyBg);
                &:hover{
                    img{
                        transform: scale(1.1);
                        transition: 3s ease;

                    }
                }
                img{
                    height: 100%;
                    object-fit: cover;
                }
            }
            .postDetails{
                h3{
                    color: var(--blackColor);
                    font-weight: 600;
                    padding-bottom: 10px;

                }
                p{
                    font-size: 13px;
                    color: var(--textColor);
                    font-weight: 500;

                }
              
            }
            a{
                font-size: 15px;
                color: var(--PrimaryColor);
                font-weight: 600;
                transition: .5s ease;
                padding-top: .5rem;
                width: max-content;
                &:hover{
                    color: var(--HoverColor);
                    .icon{
                        transform: translateX(5px);
                        
                    }
                }
            }
        }
    }
}


//MEDIA QUERIES
@media screen and (min-width: 884px) {
    .blog{
        .mainContainer{
            grid-template-columns: repeat(2, 1fr);
        }
    }
}